<template>
    <div>
        <base-header class="pb-6">
            <div class="row align-items-center py-4">
                <div class="col-lg-6 col-7">
                    <h6 class="h2 text-white d-inline-block mb-0"></h6>
                </div>      
            </div>
        </base-header>
        <div class="container-fluid mt--6">
            <div class="card mb-4">
                <div class="card-header mb--4">
                    <div class="row">
                        <div class="col-3">
                            <span class="float-right">
                                <ul class="pagination" role="navigation">
                                    <li class="page-item disabled" aria-disabled="true">
                                        <span class="page-link">«</span>
                                    </li>
                            
                                    <li class="page-item">
                                        <a class="page-link" href="http://cb8ea0e1d9f4.ngrok.io/ItemName?page=2" rel="next">»</a>
                                    </li>
                                </ul>
                            </span>
                            <span>100 of 68302</span>
                        </div>
                        <div class="col-6">
                            <base-input input-classes="form-control-sm text-center" v-model="department.search" :placeholder="tt('search')"/>
                        </div>
                        <div class="col-3">
                            <el-dropdown trigger="click">
                                <span class="el-dropdown-link btn btn-default btn-sm">
                                    View<i class="el-icon-arrow-down el-icon--right"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <div class="container">
                                        <base-checkbox v-model="department.material_number" class="mb--1">
                                            Material Number
                                        </base-checkbox>
                                        <base-checkbox v-model="department.item_name" class="mb--1">
                                            Item Name
                                        </base-checkbox>
                                        <base-checkbox v-model="department.short_description" class="mb--1">
                                            Short Description
                                        </base-checkbox>
                                        <base-checkbox v-model="department.source_description" class="mb--1">
                                            Source Description
                                        </base-checkbox>
                                        <base-checkbox v-model="department.uom" class="mb--1">
                                            UOM
                                        </base-checkbox>
                                        <base-checkbox v-model="department.material_type" class="mb--1">
                                            Material Type
                                        </base-checkbox>
                                        <base-checkbox v-model="department.material_group" class="mb--1">
                                            Material Group
                                        </base-checkbox>
                                        <base-checkbox v-model="department.catalog_type" class="mb--1">
                                            Catalog Type
                                        </base-checkbox>
                                        <base-checkbox v-model="department.catalog_status" class="mb--1">
                                            Catalog Status
                                        </base-checkbox>
                                    </div>
                                </el-dropdown-menu>
                            </el-dropdown>
                            <div class="btn-group">
                                
                            <base-button size="sm" class="btn-outline-dark" @click="remove">{{ tt('save') }}</base-button>
                            <el-dropdown trigger="click" class="btn-group">
                                <span class="el-dropdown-link btn btn-outline-dark btn-sm">
                                    CAT<i class="el-icon-arrow-down el-icon--right"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <div class="container">
                                        <base-checkbox v-model="department.material_number" class="mb--1">
                                            CAT
                                        </base-checkbox>
                                        <base-checkbox v-model="department.item_name" class="mb--1">
                                            NYC
                                        </base-checkbox>
                                        <base-checkbox v-model="department.short_description" class="mb--1">
                                            QA
                                        </base-checkbox>
                                        <base-checkbox v-model="department.source_description" class="mb--1">
                                            REV
                                        </base-checkbox>
                                        <base-checkbox v-model="department.uom" class="mb--1">
                                            SAP
                                        </base-checkbox>
                                        <base-checkbox v-model="department.material_type" class="mb--1">
                                            VER
                                        </base-checkbox>
                                    </div>
                                </el-dropdown-menu>
                            </el-dropdown>
                            </div>
                        </div>
                    </div>
                </div>
                <el-table ref="multipleTable" :data="table.data" style="width: 100%" @selection-change="handleSelectionChange">
                    <el-table-column type="selection" width="55">
                    </el-table-column>
                    <el-table-column :label="tt('serv_no')" :prop="tt('serv_no')" min-width="200px" sortable>
                        <template v-slot="{row}">
                            {{row.serv_no}}
                        </template>
                    </el-table-column>
                    <el-table-column :label="tt('item_name')" :prop="tt('item_name')" min-width="200px" sortable>
                        <template v-slot="{row}">
                            {{row.item_name}}
                        </template>
                    </el-table-column>
                    <el-table-column :label="tt('short_description')" :prop="tt('short_description')" min-width="200px" sortable>
                        <template v-slot="{row}">
                            {{row.short_description}}
                        </template>
                    </el-table-column>
                    <el-table-column :label="tt('uom')" :prop="tt('uom')" min-width="200px" sortable>
                        <template v-slot="{row}">
                            {{row.uom}}
                        </template>
                    </el-table-column>
                    <el-table-column :label="tt('category')" :prop="tt('category')" min-width="200px" sortable>
                        <template v-slot="{row}">
                            {{row.category}}
                        </template>
                    </el-table-column>
                    <el-table-column :label="tt('group')" :prop="tt('group')" min-width="200px" sortable>
                        <template v-slot="{row}">
                            {{row.group}}
                        </template>
                    </el-table-column>
                    <el-table-column :label="tt('valuation_class')" :prop="tt('valuation_class')" min-width="200px" sortable>
                        <template v-slot="{row}">
                            {{row.valuation_class}}
                        </template>
                    </el-table-column>
                    <el-table-column :label="tt('cat_type')" :prop="tt('cat_type')" min-width="200px" sortable>
                        <template v-slot="{row}">
                            {{row.cat_type}}
                        </template>
                    </el-table-column>
                    <el-table-column :label="tt('cat_status')" :prop="tt('cat_status')" min-width="200px" sortable>
                        <template v-slot="{row}">
                            {{row.cat_status}}
                        </template>
                    </el-table-column>
                    <el-table-column min-width="100px">
                        <i class="fa fa-window-close text-danger pr-2" @click="create"></i>
                        <i class="fa fa-history text-gray-dark" @click="view"></i>
                    </el-table-column>
                </el-table>
                <div class="card-body">
                    <el-tabs type="border-card">
                        <el-tab-pane label="DETAIL MATERIAL & DESCRIPTION">
                            <div class="row">
                                <div class="col-6">
                                    <div class="card">
                                        <div class="card-header">
                                            <div class="row">
                                                <div class="col-6">
                                                    <h3>DETAIL MATERIAL</h3>
                                                </div>
                                                <div class="col-6 text-right">
                                                    <base-button size="sm">
                                                        Save
                                                    </base-button>
                                                </div>
                                            </div>
                                            <div style="height:446px;overflow-y: scroll;">
                                                <div class="row">
                                                    <div class="col-8">
                                                        <label class="form-control-label">{{ tt('item_name') }} <span class="text-danger">*</span></label>
                                                        <base-input :name="tt('item_name')" :placeholder="tt('item_name')" v-model="department.item_name" rules="required"></base-input>
                                                    </div>
                                                    <div class="col-4">
                                                        <label class="form-control-label">{{ tt('item_type') }} <span class="text-danger">*</span></label>
                                                        <base-input :name="tt('item_type')" rules="required">
                                                            <el-select class="select-danger" size="small" v-model="department.item_type" placeholder="Choose Item Type">
                                                                <el-option class="select-danger" value="01" label="Abbrevation" key="Abbrevation"></el-option>
                                                            </el-select>
                                                        </base-input>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <label class="form-control-label">{{ tt('group_class') }} <span class="text-danger">*</span></label>
                                                        <base-input :name="tt('group_class')" rules="required">
                                                            <el-select class="select-danger" size="small" v-model="department.group_class" placeholder="Choose Group Class">
                                                                <el-option class="select-danger" value="01" label="Abbrevation" key="Abbrevation"></el-option>
                                                            </el-select>
                                                        </base-input>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-6">
                                                        <label class="form-control-label">{{ tt('service_category') }} <span class="text-danger">*</span></label>
                                                        <base-input :name="tt('service_category')" rules="required">
                                                            <el-select class="select-danger" size="small" v-model="department.service_category" placeholder="Choose Service Category">
                                                                <el-option class="select-danger" value="01" label="Abbrevation" key="Abbrevation"></el-option>
                                                            </el-select>
                                                        </base-input>
                                                    </div>
                                                    <div class="col-6">
                                                        <label class="form-control-label">{{ tt('service_group') }} <span class="text-danger">*</span></label>
                                                        <base-input :name="tt('service_group')" rules="required">
                                                            <el-select class="select-danger" size="small" v-model="department.service_group" placeholder="Choose Service Group">
                                                                <el-option class="select-danger" value="01" label="Abbrevation" key="Abbrevation"></el-option>
                                                            </el-select>
                                                        </base-input>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-6">
                                                        <label class="form-control-label">{{ tt('unit_of_measure') }} <span class="text-danger">*</span></label>
                                                        <base-input :name="tt('unit_of_measure')" :placeholder="tt('unit_of_measure')" v-model="department.unit_of_measure" rules="required"></base-input>
                                                    </div>
                                                    <div class="col-6">
                                                        <label class="form-control-label">{{ tt('valuation_class') }} <span class="text-danger">*</span></label>
                                                        <base-input :name="tt('valuation_class')" :placeholder="tt('valuation_class')" v-model="department.valuation_class" rules="required"></base-input>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-6">
                                                        <label class="form-control-label">{{ tt('catalog_type') }} <span class="text-danger">*</span></label>
                                                        <base-input :name="tt('catalog_type')" :placeholder="tt('catalog_type')" v-model="department.catalog_type" rules="required"></base-input>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col">
                                                        <base-input label="Note">
                                                            <textarea class="form-control" id="exampleFormControlTextarea3" rows="3"></textarea>
                                                        </base-input>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="card">
                                        <div class="card-header">
                                            <div class="row">
                                                <div class="col-6">
                                                    <h3>DESCRIPTION</h3>
                                                </div>
                                                <div class="col-6 text-right">
                                                    <base-button size="sm">
                                                        Save
                                                    </base-button>
                                                </div>
                                            </div>
                                        </div>
                                        <el-table height="500px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="table.data">
                                            <el-table-column :label="tt('characteristics')" :prop="tt('characteristics')" min-width="200px" sortable>
                                                <template v-slot="{row}">
                                                    {{row.characteristics}}
                                                </template>
                                            </el-table-column>
                                            <el-table-column :label="tt('value')" :prop="tt('value')" min-width="150px" sortable>
                                                <template v-slot="{row}">
                                                    {{row.value}}
                                                </template>
                                            </el-table-column>
                                            <el-table-column :label="tt('view')" :prop="tt('view')" min-width="150px" sortable>
                                                <template v-slot="{row}">
                                                    {{row.view}}
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                    </div>
                                </div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="KEYWORD & MCR NUMBER">
                            <div class="row">
                                <div class="col-6">
                                    <div class="card">
                                        <div class="card-header">
                                            <div class="row">
                                                <div class="col-6">
                                                    <h3>{{ tt('keyword') }}</h3>
                                                </div>
                                                <div class="col-6 text-right">
                                                    <base-button size="sm" @click="createKeyword">
                                                        {{ tt('add_new') }}
                                                    </base-button>
                                                </div>
                                            </div>
                                        </div>
                                        <el-table height="500px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="table.data">
                                            <el-table-column :label="tt('keyword')" :prop="tt('keyword')" min-width="200px" sortable>
                                                <template v-slot="{row}">
                                                    {{row.keyword}}
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="card">
                                        <div class="card-header">
                                            <div class="row">
                                                <div class="col-6">
                                                    <h3>{{ tt('mcr_number') }}</h3>
                                                </div>
                                                <div class="col-6 text-right">
                                                    <base-button size="sm" @click="createPlant">
                                                        {{ tt('add_new') }}
                                                    </base-button>
                                                </div>
                                            </div>
                                        </div>
                                        <el-table height="500px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="table.data">
                                            <el-table-column :label="tt('mcr_number')" :prop="tt('mcr_number')" min-width="200px" sortable>
                                                <template v-slot="{row}">
                                                    {{row.mcr_number}}
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                    </div>
                                </div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="PURCHASE ORDER TEXT">
                            <div class="row">
                                <div class="col-6">
                                    <div class="card">
                                    </div>
                                </div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="ATTACHMENT">
                            <div class="row">
                                <div class="col-6">
                                    <div class="card">
                                        <div class="card-header">
                                            <div class="row">
                                                <div class="col-6">
                                                    <h3>{{ tt('attachment_pdf') }}</h3>
                                                </div>
                                                <div class="col-6 text-right">
                                                    <base-button size="sm" @click="createAttachmentPdf">
                                                        {{ tt('add_new') }}
                                                    </base-button>
                                                </div>
                                            </div>
                                        </div>
                                        <el-table height="500px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="table.data">
                                            <el-table-column :label="tt('file_name')" :prop="tt('file_name')" min-width="200px" sortable>
                                                <template v-slot="{row}">
                                                    {{row.file_name}}
                                                </template>
                                            </el-table-column>
                                            <el-table-column :label="tt('upload_by')" :prop="tt('upload_by')" min-width="150px" sortable>
                                                <template v-slot="{row}">
                                                    {{row.upload_by}}
                                                </template>
                                            </el-table-column>
                                            <el-table-column :label="tt('created_at')" :prop="tt('created_at')" min-width="150px" sortable>
                                                <template v-slot="{row}">
                                                    {{row.created_at}}
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="card">
                                        <div class="card-header">
                                            <div class="row">
                                                <div class="col-6">
                                                    <h3>{{ tt('attachment_web') }}</h3>
                                                </div>
                                                <div class="col-6 text-right">
                                                    <base-button size="sm" @click="createAttachmentWeb">
                                                        {{ tt('add_new') }}
                                                    </base-button>
                                                </div>
                                            </div>
                                        </div>
                                        <el-table height="500px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="table.data">
                                            <el-table-column :label="tt('web_link')" :prop="tt('web_link')" min-width="200px" sortable>
                                                <template v-slot="{row}">
                                                    {{row.web_link}}
                                                </template>
                                            </el-table-column>
                                            <el-table-column :label="tt('upload_by')" :prop="tt('upload_by')" min-width="200px" sortable>
                                                <template v-slot="{row}">
                                                    {{row.upload_by}}
                                                </template>
                                            </el-table-column>
                                            <el-table-column :label="tt('created_at')" :prop="tt('created_at')" min-width="200px" sortable>
                                                <template v-slot="{row}">
                                                    {{row.created_at}}
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                    </div>
                                </div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="IMAGE">IMAGE</el-tab-pane>
                        <el-tab-pane label="SOURCE">SOURCE</el-tab-pane>
                    </el-tabs>

                    <div class="row">
                        <div class="col-12 pull-right">
                             <div v-if="formImage.show" id="modal-custom" class="draggable ui-draggable ui-draggable-handle" style="position: fixed; border-radius: 3px; width: 35%; bottom: 0px; z-index: 1000">
                                <div class="card shadow">
                                    <div class="card-header">
                                        <div class="row">
                                            <div class="col">
                                                <span><b>IMAGE</b></span>
                                            </div>
                                            <div class="col text-right">
                                                <base-button size="sm" disabled>
                                                    Add New
                                                </base-button>
                                                <span class="close float-right" id="close-modal-custom" @click="closeImage">×</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <span id="image_big"></span>
                                    </div>
                                    <div class="card-footer">
                                        <span id="image_small"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal :show.sync="form.show">
            <template slot="header">
                <h5 class="modal-title">REJECT - -001/2020-1</h5>
            </template>
            <div>
                <label class="form-control-label">{{ tt('message') }} <span class="text-danger">*</span></label>
                <base-input>
                    <textarea class="form-control" :placeholder="tt('tolong_diperbaiki')" id="exampleFormControlTextarea3" rows="5"></textarea>
                </base-input>
            </div>
            <template slot="footer">
                <base-button type="secondary">{{ tt('cancle') }}</base-button>
                <base-button type="primary">
                    {{ tt('reject') }}
                </base-button>
            </template>
        </modal>
        <modal :show.sync="formView.show">
            <template slot="header">
                <h5 class="modal-title">{{ tt('cat_status_change_log') }}</h5>
            </template>
            <el-table height="500px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="table.data">
                <el-table-column :label="tt('updated_by')" :prop="tt('updated_by')" sortable>
                    <template v-slot="{row}">
                        {{row.updated_by}}
                    </template>
                </el-table-column>
                <el-table-column :label="tt('cat_status')" :prop="tt('cat_status')" sortable>
                    <template v-slot="{row}">
                        {{row.cat_status}}
                    </template>
                </el-table-column>
                <el-table-column :label="tt('updated_at')" :prop="tt('updated_at')" sortable>
                    <template v-slot="{row}">
                        {{row.updated_at}}
                    </template>
                </el-table-column>
            </el-table>
            <template slot="footer">
                <b>Material Number : -001/2020-1</b>
            </template>
        </modal>
        <modal :show.sync="formManufacture.show">
            <template slot="header">
                <h5 class="modal-title">{{ tt('add_new_manufacture') }}</h5>
            </template>
            <div>
                <label class="form-control-label">{{ tt('manufacture') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('manufacture')" :placeholder="tt('manufacture')" v-model="department.manufacture" rules="required"></base-input>

                <label class="form-control-label">{{ tt('manufacture_refference_max_40') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('manufacture_refference_max_40')" :placeholder="tt('manufacture_refference_max_40')" v-model="department.manufacture_refference_max_40" rules="required"></base-input>

                <label class="form-control-label">{{ tt('manref_description') }} <span class="text-danger">*</span></label>
                <base-input>
                    <textarea class="form-control" id="exampleFormControlTextarea3" rows="3"></textarea>
                </base-input>

                <label class="form-control-label">{{ tt('source_type') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('source_type')" rules="required">
                    <el-select class="select-danger" v-model="department.source_type" placeholder="Choose source_type">
                        <el-option class="select-danger" value="01" label="Abbrevation" key="Abbrevation"></el-option>
                    </el-select>
                </base-input>

                <label class="form-control-label">{{ tt('manref_type') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('manref_type')" rules="required">
                    <el-select class="select-danger" v-model="department.manref_type" placeholder="Choose manref_type">
                        <el-option class="select-danger" value="01" label="Abbrevation" key="Abbrevation"></el-option>
                    </el-select>
                </base-input>

                <label class="form-control-label">{{ tt('valid_until') }} <span class="text-danger">*</span></label>
                <base-input type="date" :name="tt('valid_until')" :placeholder="tt('valid_until')" v-model="department.valid_until" rules="required"></base-input>

                <label class="form-control-label">{{ tt('note') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('note')" :placeholder="tt('note')" v-model="department.note" rules="required"></base-input>
            </div>
            <template slot="footer">
                <base-button type="secondary">{{ tt('reset') }}</base-button>
                <base-button type="primary">
                    {{ tt('add') }}
                </base-button>
            </template>
        </modal>
        <modal :show.sync="formPlant.show">
            <template slot="header">
                <h5 class="modal-title">{{ tt('add_new_plant') }}</h5>
            </template>
            <div>
                <label class="form-control-label">{{ tt('plant') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('plant')" :placeholder="tt('plant')" v-model="department.plant" rules="required"></base-input>

                <label class="form-control-label">{{ tt('plant_sp') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('plant_sp')" rules="required">
                    <el-select class="select-danger" v-model="department.plant_sp" placeholder="Choose plant_sp">
                        <el-option class="select-danger" value="01" label="Abbrevation" key="Abbrevation"></el-option>
                    </el-select>
                </base-input>
            </div>
            <template slot="footer">
                <base-button type="secondary">{{ tt('reset') }}</base-button>
                <base-button type="primary">
                    {{ tt('add') }}
                </base-button>
            </template>
        </modal>
        <modal :show.sync="formStorageLocation.show">
            <template slot="header">
                <h5 class="modal-title">{{ tt('add_new_storage_location') }}</h5>
            </template>
            <div>
                <label class="form-control-label">{{ tt('plant_code') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('plant_code')" rules="required">
                    <el-select class="select-danger" v-model="department.plant_code" placeholder="Choose Plant Code">
                        <el-option class="select-danger" value="01" label="Abbrevation" key="Abbrevation"></el-option>
                    </el-select>
                </base-input>

                <label class="form-control-label">{{ tt('location_code') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('location_code')" rules="required">
                    <el-select class="select-danger" v-model="department.location_code" placeholder="Choose Location Code">
                        <el-option class="select-danger" value="01" label="Abbrevation" key="Abbrevation"></el-option>
                    </el-select>
                </base-input>
            </div>
            <template slot="footer">
                <base-button type="secondary">{{ tt('reset') }}</base-button>
                <base-button type="primary">
                    {{ tt('add') }}
                </base-button>
            </template>
        </modal>
        <modal :show.sync="formEquipment.show">
            <template slot="header">
                <h5 class="modal-title">{{ tt('add_new_equipment') }}</h5>
            </template>
            <div>
                <label class="form-control-label">{{ tt('plant') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('plant')" rules="required">
                    <el-select class="select-danger" v-model="department.plant" placeholder="Choose Plant">
                        <el-option class="select-danger" value="01" label="Abbrevation" key="Abbrevation"></el-option>
                    </el-select>
                </base-input>

                <label class="form-control-label">{{ tt('equipment_code') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('equipment_code')" :placeholder="tt('equipment_code')" v-model="department.equipment_code" rules="required"></base-input>

                <label class="form-control-label">{{ tt('quantity_installed') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('quantity_installed')" :placeholder="tt('quantity_installed')" v-model="department.quantity_installed" rules="required"></base-input>
            </div>
            <template slot="footer">
                <base-button type="secondary">{{ tt('reset') }}</base-button>
                <base-button type="primary">
                    {{ tt('add') }}
                </base-button>
            </template>
        </modal>
        <modal :show.sync="formAccounting.show">
            <template slot="header">
                <h5 class="modal-title">{{ tt('add_new_accounting') }}</h5>
            </template>
            <div>
                <label class="form-control-label">{{ tt('valuation_class') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('valuation_class')" rules="required">
                    <el-select class="select-danger" v-model="department.valuation_class" placeholder="Choose valuation_class">
                        <el-option class="select-danger" value="01" label="Abbrevation" key="Abbrevation"></el-option>
                    </el-select>
                </base-input>

                <label class="form-control-label">{{ tt('price_control') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('price_control')" :placeholder="tt('price_control')" v-model="department.price_control" rules="required"></base-input>

                <label class="form-control-label">{{ tt('standard_price') }}</label>
                <base-input :name="tt('standard_price')" :placeholder="tt('standard_price')" v-model="department.standard_price" rules="required"></base-input>

                <label class="form-control-label">{{ tt('price_unit') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('price_unit')" :placeholder="tt('price_unit')" v-model="department.price_unit" rules="required"></base-input>
            </div>
            <template slot="footer">
                <base-button type="secondary">{{ tt('reset') }}</base-button>
                <base-button type="primary">
                    {{ tt('add') }}
                </base-button>
            </template>
        </modal>
        <modal :show.sync="formKeyword.show">
            <template slot="header">
                <h5 class="modal-title">{{ tt('add_new_keyword') }}</h5>
            </template>
            <div>
                <label class="form-control-label">{{ tt('keyword') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('keyword')" :placeholder="tt('keyword')" v-model="department.keyword" rules="required"></base-input>
            </div>
            <template slot="footer">
                <base-button type="secondary">{{ tt('reset') }}</base-button>
                <base-button type="primary">
                    {{ tt('add') }}
                </base-button>
            </template>
        </modal>
        <modal :show.sync="formAttachmentPdf.show">
            <template slot="header">
                <h5 class="modal-title">{{ tt('add_new_pdf') }}</h5>
            </template>
            <div>
                <label class="form-control-label">{{ tt('pdf_file') }} <span class="text-danger">*</span></label>
                <file-input @change="filesChange"></file-input>
            </div>
            <template slot="footer">
                <base-button type="secondary">{{ tt('reset') }}</base-button>
                <base-button type="primary">
                    {{ tt('add') }}
                </base-button>
            </template>
        </modal>
        <modal :show.sync="formAttachmentWeb.show">
            <template slot="header">
                <h5 class="modal-title">{{ tt('add_new_web_link') }}</h5>
            </template>
            <div>
                <label class="form-control-label">{{ tt('web_link') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('web_link')" :placeholder="tt('web_link')" v-model="department.web_link" rules="required"></base-input>
            </div>
            <template slot="footer">
                <base-button type="secondary">{{ tt('reset') }}</base-button>
                <base-button type="primary">
                    {{ tt('add') }}
                </base-button>
            </template>
        </modal>
    </div>
</template>
<script>
    export default {        
        data() {
            return {                
                form: {
                    show: false
                },              
                formView: {
                    show: false
                }, 
                formImage: {
                    show: false
                },              
                formManufacture: {
                    show: false
                },              
                formPlant: {
                    show: false
                },              
                formStorageLocation: {
                    show: false
                },              
                formEquipment: {
                    show: false
                },              
                formAccounting: {
                    show: false
                },              
                formKeyword: {
                    show: false
                },              
                formAttachmentPdf: {
                    show: false
                },              
                formAttachmentWeb: {
                    show: false
                },
                table: {
                    data: [
                        {
                            id: 1,
                            serv_no: "-001/2020-1", 
                            item_name: '', 
                            short_description: '',
                            uom: "UNT",
                            category: '901',
                            group: '901001',
                            valuation_class: "9001",
                            cat_type: "SWH",
                            cat_status: "CAT",
                        },
                        {
                            id: 2,
                            serv_no: "-001/2020-1", 
                            item_name: '', 
                            short_description: '',
                            uom: "UNT",
                            category: '901',
                            group: '901001',
                            valuation_class: "1001",
                            cat_type: "",
                            cat_status: "QA",
                        },
                        {
                            id: 3,
                            serv_no: "-001/2020-1", 
                            item_name: '', 
                            short_description: '',
                            uom: "UNT",
                            category: '901',
                            group: '901001',
                            valuation_class: "9002",
                            cat_type: "SIT",
                            cat_status: "CAT",
                        },
                    ]
                },        
                department: {
                    
                },
                tableData: [{
                  date: '2016-05-03',
                  name: 'Tom',
                  address: 'No. 189, Grove St, Los Angeles'
                }, {
                  date: '2016-05-02',
                  name: 'Tom',
                  address: 'No. 189, Grove St, Los Angeles'
                }, {
                  date: '2016-05-04',
                  name: 'Tom',
                  address: 'No. 189, Grove St, Los Angeles'
                }, {
                  date: '2016-05-01',
                  name: 'Tom',
                  address: 'No. 189, Grove St, Los Angeles'
                }, {
                  date: '2016-05-08',
                  name: 'Tom',
                  address: 'No. 189, Grove St, Los Angeles'
                }, {
                  date: '2016-05-06',
                  name: 'Tom',
                  address: 'No. 189, Grove St, Los Angeles'
                }, {
                  date: '2016-05-07',
                  name: 'Tom',
                  address: 'No. 189, Grove St, Los Angeles'
                }],
                multipleSelection: []
            }
        },
        methods: {
            handleTableAction(command) {
                switch (command.action) {
                    case 'edit':
                            this.edit(command.data);
                        break;
                    case 'remove':
                            this.remove(command.data);
                        break;
                    default:
                        break;
                }
            },
            image() {
                this.formImage.show = true
            },
            createManufacture() {
                this.formManufacture.show = true
            },
            createPlant() {
                this.formPlant.show = true
            },
            createStorageLocation() {
                this.formStorageLocation.show = true
            },
            createEquipment() {
                this.formEquipment.show = true
            },
            createAccounting() {
                this.formAccounting.show = true
            },
            createKeyword() {
                this.formKeyword.show = true
            },
            createAttachmentPdf() {
                this.formAttachmentPdf.show = true
            },
            createAttachmentWeb() {
                this.formAttachmentWeb.show = true
            },
            closeImage() {
                this.formImage.show = false
            },
            create() {
                this.form.show = true;
            },
            view() {
                this.formView.show = true;
            },
            edit(id) {
                this.form.add = false;
                this.form.title = "Update Department";
                this.form.show = true;
            },
            remove(id) {
                this.confirmDialog("Are you sure to update this data?").then((result) => {
                    if (result.value) {
                        this.alertDialog('success', 'Successed update data');
                    } else {
                        this.alertDialog('error', 'Failed update data');
                    }
                })
            },
        }   
    };
</script>
<style></style>
